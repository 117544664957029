<template>
    <div class="positioningDevices-set">
        <el-form
            ref="form"
            :model="form"
            label-width="80px"
            :rules="rules"
        >
            <el-form-item label="回写设备" prop="back_type">
                <el-radio-group v-model="form.back_type">
                    <el-radio :label="0">
                        APP
                    </el-radio>
                    <el-radio :label="1">
                        GPS
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="定位频率" prop="interval">
                <el-input v-model="form.interval" class="interval-input"></el-input>秒
            </el-form-item>
            <el-form-item class="btn-con">
                <el-button @click="cancel" class="cancel-btn" size="medium">
                    取消
                </el-button>
                <el-button type="primary" @click="onSubmit" size="medium">
                    确定
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            form: {
                back_type: 0,
                interval: 20,
            },
            rules: {
                back_type: [
                    { required: true, message: '回写设备', trigger: 'change' },
                ],
                interval: [
                    { required: true, message: '定位频率', trigger: 'change' },
                ],
            },
        };
    },
    watch: {},
    computed: {},
    methods: {
        onSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$axios
                        .put(`/interfaceApi/logistics/car_config_manager/batch_config/${this.extr.car_license}`
                + `/${this.form.back_type}/${this.form.interval}`)
                        .then(res => {
                            if (res) {
                                this.$message.success('设置成功！');
                                window.updateTable();
                                window.hideToast();
                            }
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else {
                    this.$message.warning('验证不成功，请重新填写');
                    return false;
                }
            });

        },
        cancel() {
            window.hideToast();
        },
    },
    created() {},
    mounted() {
        if (this.extr.back_type) {
            this.extr.back_type === 'APP' ? this.form.back_type = 0 : this.form.back_type = 1;
        }
        if (this.extr.interval) {
            this.form.interval = this.extr.interval;
        }
    },
};
</script>
<style lang="stylus">
.positioningDevices-set
    padding .2rem
    .el-form-item
        padding-left: .5rem;
        .interval-input
            width auto
            margin-right .1rem
            input
                width 2rem
    .btn-con
        // text-align center
        margin-top .5rem
        .el-form-item__content
            margin-left 0!important
            text-align center
        .cancel-btn
            margin-right .2rem
</style>